import { User } from 'models/users';
import React, { createContext, ReactNode, useContext } from 'react';
import { useAuth } from './AuthContext';

type UserContextType = {
  user: User | undefined,
  token: string | undefined,
};

const UserContext = createContext<UserContextType>({ user: undefined, token: undefined });

const useUser = () => {
  return useContext(UserContext);
}

const UserProvider = ({ children }: { children: ReactNode }) => {
  const { user, accessToken } = useAuth();

  return (
    <UserContext.Provider value={{ user, token: accessToken }}>
      {children}
    </UserContext.Provider>
  )
}

export { UserProvider, useUser };

import { apiClient } from "./api-client";

export const getCustomization = async () => {
  const { data } = await apiClient.get(`/customization`);
  return data;
}

export const updateCustomization = async (customization: any) => {
  const needed = {
    title: customization.title,
    subtitle: customization.subtitle,
    color: customization.color
  };
  const response = await apiClient.post("/customization", { ...needed });
  return response;
}
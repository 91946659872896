import { useCustomization } from "context/CustomizationContext";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Check } from "tabler-icons-react";

const Widget = () => {
  const navigate = useNavigate();
  const { customization } = useCustomization();

  return (
    <div className="flex justify-between items-center w-full">
      <div className="flex items-center justify-center space-x-4">
        {customization.customized ? (
          <button
            disabled
            className="rounded-full p-0 text-white flex items-center justify-center w-8 h-8 bg-blue-500"
          >
            <Check className="text-white" />
          </button>
        ) : (
          <button
            disabled
            className="rounded-full p-4 text-white flex items-center justify-center w-8 h-8 bg-blue-500"
          >
            3
          </button>
        )}
        {customization.customized ? (
          <p>You have customized your widget.</p>
        ) : (
          <p>Customize your widget.</p>
        )}
      </div>
      <div>
        <button
          disabled={customization.customized ?? false}
          onClick={() => navigate("/settings")}
          className="py-2 px-4 bg-blue-400 rounded text-gray-50 disabled:bg-gray-50 disabled:border-blue-300 disabled:border disabled:text-blue-300 disabled:cursor-not-allowed"
        >
          {customization.customized ? "Customized" : "Customize"}
        </button>
      </div>
    </div>
  );
};

export default Widget;

import React from 'react'

export enum Color {
  Light = 'light',
  Dark = 'dark'
}

export enum Size {
  xs = 1,
  sm = 2,
  md = 3,
  xl = 4
}

type Props = {
  color?: Color
  size?: Size
}

const Loading = ({ color = Color.Light, size = Size.xs}: Props) => {
  return (
    <svg className="animate-spin text-blue-400 h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-20" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-100" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  )
}

export default Loading

import { showNotification } from "@mantine/notifications";
import { Customer } from "models/users";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useAxios } from "./AxiosContext";
import { useSocket } from "./SocketContext";

type ChatContextType = {
  sendMessage: (message: string) => void;
  receiveMessage: () => void;
  newConversation: (conversation: any) => void;
  getConversations: () => Promise<Customer[]>;
  conversations: any[];
  setConversations: (conversations: any) => void;
  messages: any[];
  isLoading: boolean;
};

const ChatContext = createContext<ChatContextType>({} as ChatContextType);

export const useChat = (): ChatContextType => {
  return useContext(ChatContext);
};

export const ChatProvider = ({ children }: { children: ReactNode }) => {
  const { api } = useAxios();
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState<any[][]>([]);
  const [fetchMessages, setFetchMessages] = useState(false);
  const { socket } = useSocket();
  const [initReception, setInitReception] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (conversations.length === 0 && !fetchMessages) {
      setFetchMessages(true);
      getConversations();
      return;
    }
    if (conversations.length !== 0 && !initReception) {
      setInitReception(true);
    }
  }, [conversations, initReception]);

  useEffect(() => {
    if (socket) receiveMessage();
  }, [socket]);

  type Conversation = {
    cursor: number;
    customer: Customer;
    messages: any[];
    messagesLoading: boolean;
  };

  const getConversations = async () => {
    const response = await api!.get("/chat/conversations/company");
    setIsLoading(true);
    const conversations = response.data;
    setConversations(conversations.conversations);
    setIsLoading(false);
  };

  const receiveMessage = () => {
    socket?.on("message", (message: any) => {
      if (!message.seen_by_company) {
        showNotification({
          title: "New message from customer",
          message: message.content,
          onClick: () => {
            navigate("/conversations/all/" + message?.sender?.id);
          },
          style: {
            cursor: "pointer",
          },
        });
      }
      setConversations((conversations: any[]) => {
        let updatedConversation = conversations.find(
          (conversation: Conversation) =>
            conversation?.customer?.id === message?.sender?.id ||
            conversation?.customer?.id === message?.to?.id
        );
        if (!updatedConversation) {
          updatedConversation = {
            cursor: 0,
            customer: message.sender,
            messages: [
              {
                ...message,
                content: message.content,
                id: crypto.randomUUID(),
                sender: message.from ? "COMPANY" : "CUSTOMER",
                createdAt: Date.now(),
              },
            ],
            messagesLoading: false,
          };
        } else {
          updatedConversation.messages.unshift({
            ...message,
            content: message.content,
            id: crypto.randomUUID(),
            sender: message.from ? "COMPANY" : "CUSTOMER",
            createdAt: Date.now(),
          });
        }
        const convIndex = conversations.findIndex(
          (conversation: any) =>
            conversation.customer.id ===
            (message?.sender?.id || message?.to?.id)
        );
        if (convIndex !== -1) {
          conversations.splice(convIndex, 1);
        }
        conversations.unshift(updatedConversation);
        return [...conversations];
      });
    });
  };

  return (
    <ChatContext.Provider
      value={
        {
          conversations,
          isLoading,
          messages,
          setConversations,
        } as ChatContextType
      }
    >
      {children}
    </ChatContext.Provider>
  );
};

import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe, PaymentMethod } from "@stripe/stripe-js";
import LightLoading from "components/LigthLoading";
import { usePayment } from "context/PaymentContext";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { apiClient } from "service/api-client";
import { Check } from "tabler-icons-react";

interface PaymentProps {
  name: string;
  price: number;
  product: any;
}

const Card = () => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const { customer } = usePayment();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { refetch } = usePayment();

  const { name, price, product } = location.state as PaymentProps;

  if (price === undefined || name === undefined)
    return <div>Something went wrong.</div>;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    stripe
      ?.createPaymentMethod({
        type: "card",
        card: elements!.getElement(CardElement)!,
      })
      .then((result: any) => {
        if (result.error) {
          alert("Something went wrong, please try again or contact us.");
        } else {
          handlePaymentCreation(result.paymentMethod, customer.id);
        }
      });
  };

  const handlePaymentCreation = async (
    method: PaymentMethod,
    customerId: string
  ) => {
    apiClient
      .post("/payment/create/subscription", {
        customerId,
        paymentId: method.id,
        price: product.default_price,
        name: product.name,
      })
      .then()
      .then()
      .then(async () => {
        setLoading(false);
        setSuccess(true);
        refetch();
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigate(-1);
      })
      .catch((error) => {
        setLoading(false);
        setError("Something went wrong.");
        alert(error);
      });
  };
  return (
    <div className="flex-1 flex items-center m-16 justify-center">
      <div className="max-w-md flex-1 border-gray-500 shadow rounded m-auto p-4">
        <div className="flex items-center justify-between">
          <h1>Babble</h1>
          <h1>Secure Payment</h1>
        </div>
        <div className="my-4">
          <h2>{name}</h2>
        </div>
        <form className="space-y-2" onSubmit={handleSubmit}>
          <div className="border rounded p-2">
            <CardElement options={{ hidePostalCode: true }} />
          </div>
          <div className="w-full flex items-center justify-center">
            <button
              disabled={loading || success}
              className="w-full bg-blue-500 text-gray-100 rounded py-1 disabled:cursor-not-allowed"
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <LightLoading />
                  Processing...
                </div>
              ) : success ? (
                <div className="flex items-center justify-center">
                  <Check className="bg-blue-600 rounded-full p-0.5" />
                </div>
              ) : (
                `Pay ${price}€`
              )}
            </button>
          </div>
        </form>
        <p className="flex mt-4 justify-end w-full">
          Powered by&nbsp;
          <a className="text-blue-500" href="https://stripe.com">
            Stripe
          </a>
        </p>
      </div>
    </div>
  );
};

const key = process.env.REACT_APP_PUBLISHABLE_KEY as string;
const stripePromise = loadStripe(key);

const Payment = () => {
  return (
    <Elements stripe={stripePromise}>
      <Card />
    </Elements>
  );
};

export default Payment;

import { useMediaQuery } from "@mantine/hooks";
import ConversationsContainer from "components/ConversationsContainer";
import { useChat } from "context/ChatContext";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";

const MessageLayout = () => {
  const { conversations, isLoading } = useChat();
  const smallScreen = useMediaQuery("(max-width: 768px)");
  const [collapsed, setCollapsed] = useState(false);

  return (
    <>
      <ConversationsContainer
        isLoading={isLoading}
        conversations={conversations}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        smallScreen={smallScreen}
      />
      <div className={`${collapsed ? "ml-8" : "ml-60"} h-full`}>
        <Outlet />
      </div>
    </>
  );
};

export default MessageLayout;

import React from 'react'
import Loading from './Loading'

const FullPageLoading = () => {
  return (
    <div className='h-screen w-screen'>
      <div className='flex-1 h-full'>
        <div className='flex-1 h-full flex items-center justify-center'>
          <Loading />
        </div>
      </div>
    </div>
  )
}

export default FullPageLoading
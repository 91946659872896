import { Divider, Table } from "@mantine/core";
import { useCustomers } from "context/CustomersContext";
import React from "react";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const { customers } = useCustomers();
  const navigate = useNavigate();

  const ths = (
    <tr>
      <th>Email</th>
      <th>Name</th>
      <th>Created at</th>
      <th>Id</th>
    </tr>
  );

  const rows = customers?.map((customer) => {
    const createdAt = new Date(customer.createdAt);
    const format =
      createdAt.getFullYear() +
      "/" +
      (createdAt.getMonth() + 1) +
      "/" +
      createdAt.getDate();

    return (
      <tr
        key={customer.id}
        onClick={() => navigate(`/conversations/all/${customer.id}`)}
        className="cursor-pointer"
      >
        <td>{customer.email ?? "Unknown"}</td>
        <td>{customer.name ?? "Unknown"}</td>
        <td>{format}</td>
        <td>{customer.id}</td>
      </tr>
    );
  });

  return (
    <div className="max-w-2xl m-auto py-12 md:px-0 px-4 overflow-y-scroll">
      <h1 className="text-xl font-semibold">Customers</h1>
      <Divider my={32} size="xs" className="w-full" />
      <Table striped highlightOnHover className="overflow-x-scroll">
        <thead>{ths}</thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  );
};

export default Users;

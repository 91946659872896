import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { QueryKey, SetDataOptions, useQuery, useQueryClient } from 'react-query';
import { Updater } from 'react-query/types/core/utils';
import { getAdmins, createAdmin } from '../service/admins';

type Admin = {
  name?: string;
  createdAt: string;
  email?: string;
  id: string;
}

type AdminsContextType = {
  admins: Admin[];
  createAdmin: (email: string, role: number) => Promise<any>;
}

const AdminsContext = createContext<AdminsContextType>({} as AdminsContextType);

export const useAdmins = () => useContext(AdminsContext);

export const AdminsProvider = ({ children }: { children: ReactNode }) => {
  const { data, isError, isLoading } = useQuery('admins', getAdmins);

  return (
    <AdminsContext.Provider value={{admins: data, createAdmin}} >
      {children}
    </AdminsContext.Provider>
  )
}

export default AdminsProvider;


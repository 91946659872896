import { Divider } from "@mantine/core";
import FullPageLoading from "components/FullPageLoading";
import { useCustomization } from "context/CustomizationContext";
import React from "react";
import { useQueryClient } from "react-query";
import Admin from "./Admin";
import Snippet from "./Snippet";
import Widget from "./Widget/Widget";

const Settings = () => {
  const queryClient = useQueryClient();
  const { customization, setCustomization } = useCustomization();
  const prevCustomization: object | undefined =
    queryClient.getQueryData("customization");

  if (!prevCustomization) return <FullPageLoading />;

  return (
    <div className="bg-background">

    <div className="max-w-2xl m-auto md:px-0 px-4">
      <Widget
        prevCustomization={prevCustomization}
        customization={customization}
        setCustomization={setCustomization}
      />

      <Divider my={16} size="md" className="w-full" />

      <Snippet customization={customization}/>

      <Divider my={16} size="md" className="w-full" />

      <Admin />
    </div>
    </div>
  );
};

export default Settings;

import { Container, Paper, Title } from "@mantine/core";
import Loading from "components/Loading";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { verifyEmail } from "service/email-verification";

const EmailVerification = () => {
  const { token } = useParams();
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    if (token) {
      verifyEmail(token).then(() => {
        setIsVerified(true);
      });
    }
  }, [token]);

  return (
    <>
      {isVerified ? (
        <Container size={420} pt={40}>
          <Paper withBorder shadow="md" p={40} radius="md">
            <div>
              <div>
                <Title
                  pb={10}
                  align="center"
                  sx={(theme) => ({
                    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                    fontWeight: 900,
                  })}
                >
                  Babble
                </Title>
              </div>
              <div className="flex space-x-4 items-center justify-center">
                <p>
                  Your email is verified, you can close this page and come back
                  to the app.
                </p>
              </div>
            </div>
          </Paper>
        </Container>
      ) : (
        <Container size={420} pt={40}>
          <Paper withBorder shadow="md" p={30} radius="md">
            <div>
              <div>
                <Title
                  align="center"
                  sx={(theme) => ({
                    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                    fontWeight: 900,
                  })}
                >
                  Babble
                </Title>
              </div>
              <div className="flex space-x-4 items-center justify-center">
                <Loading />
                <p>We are verifying your email address, please wait.</p>
              </div>
            </div>
          </Paper>
        </Container>
      )}
    </>
  );
};

export default EmailVerification;

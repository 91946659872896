import React from "react";
import { useQueryClient } from "react-query";

type Props = {
  color: string;
  setColor: Function;
  disabled: boolean;
};

const Color = ({ color, setColor, disabled }: Props) => {
  const queryClient = useQueryClient();
  const prevCustomization: object | undefined =
    queryClient.getQueryData("customization");

  if (!prevCustomization) return null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    setColor((prev: any) => ({ ...prev, color: e.target.value }));
  };

  const handleClick = (color: string) => {
    if (disabled) return;
    queryClient.setQueryData("customization", { ...prevCustomization, color });
  };

  return (
    <>
      <label className="text-lg text-stone-800">Main color</label>
      <div className="flex md:flex-col w-full  justify-between m-auto">
        <div className="grid grid-rows-2 grid-flow-col gap-x-2 gap-y-1 md:mb-2 md:flex md:space-x-1 md:justify-start">
          <div
            onClick={() => handleClick("#3b82f6")}
            className="w-8 h-8 cursor-pointer rounded bg-blue-500"
          />
          <div
            onClick={() => handleClick("#22c55e")}
            className="w-8 h-8 cursor-pointer rounded bg-green-500"
          />
          <div
            onClick={() => handleClick("#ef4444")}
            className="w-8 h-8 cursor-pointer rounded bg-red-500"
          />
          <div
            onClick={() => handleClick("#8b5cf6")}
            className="w-8 h-8 cursor-pointer rounded bg-purple-500"
          />
          <div
            onClick={() => handleClick("#facc15")}
            className="w-8 h-8 cursor-pointer rounded bg-yellow-400"
          />
          <div
            onClick={() => handleClick("#06b6d4")}
            className="w-8 h-8 cursor-pointer rounded bg-cyan-500"
          />
          <div
            onClick={() => handleClick("#1f2937")}
            className="w-8 h-8 cursor-pointer rounded bg-gray-800"
          />
        </div>
        <input
          className="outline-blue-500 border-2 border-gray-500 rounded py-1 px-2 w-32 h-10"
          value={color}
          onChange={(e) => handleChange(e)}
        />
      </div>
    </>
  );
};

export default Color;

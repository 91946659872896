import { usePayment } from "context/PaymentContext";
import React from "react";
import Plan from "../Plan";
import { ScrollArea } from "@mantine/core";

type Props = {
  selected: string;
  setSelected: (selected: string) => void;
  updateAction: () => void;
};

const Plans = ({ selected, setSelected, updateAction }: Props) => {
  const { subscriptions } = usePayment();

  return (
    <div className="overflow-x-scroll">
      <ScrollArea>
        <div className="flex space-x-2 mb-4">
          {subscriptions.map((subscription: any) => {
            return (
              <div
                className="cursor-pointer"
                onClick={() => setSelected(subscription.name)}
                key={subscription.name}
              >
                <Plan
                  key={subscription.name}
                  title={subscription.name}
                  selected={subscription.name == selected}
                  perks={subscription.metadata}
                  price={subscription.default_price.unit_amount / 100}
                />
              </div>
            );
          })}
        </div>
      </ScrollArea>
      <div className="absolute right-1/2 bottom-0 translate-y-1/2 translate-x-1/2">
        <button onClick={updateAction} className="bg-blue-500 rounded text-gray-100 px-4 py-1">Update</button>
      </div>
    </div>
  );
};

export default Plans;

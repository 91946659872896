import Loading from 'components/Loading';
import React, { createContext, ReactNode, useContext } from 'react'
import { useQuery } from 'react-query';
import { getEmailVerified } from 'service/auth-client';

type EmailContextType = {
  emailVerified: boolean | undefined
};

const EmailContext = createContext<EmailContextType>({ emailVerified: undefined });

const useEmail = () => {
  return useContext(EmailContext);
}

const EmailProvider = ({ children }: { children: ReactNode }) => {
  const { data, isLoading, isError, error } = useQuery('emailVerified', getEmailVerified);

  if (isLoading)
    return null

  return (
    <EmailContext.Provider value={{ emailVerified: data ?? undefined }}>
      {children}
    </EmailContext.Provider>
  )
}

export { EmailProvider, useEmail }
import { apiClient } from "./api-client";

export const getCustomers = async () => {
  try {
    const response = await apiClient.get("/customer/all");
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

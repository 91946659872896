import { Code, Divider } from "@mantine/core";
import React, { useEffect } from "react";
import { Check } from "tabler-icons-react";

type Props = {
  customization: any;
};

const Snippet = ({ customization }: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [copy, setCopy] = React.useState(false);

  const copyToClipboard = () => {
    if (!ref.current) return;

    navigator.clipboard.writeText(ref.current.innerText);
    setCopy(true);
  };

  useEffect(() => {
    if (copy) {
      setTimeout(() => setCopy(false), 2000);
    }
  }, [copy]);

  return (
    <div className="flex-1 flex-col items-center justify-center max-w-2xl m-auto py-4">
      <h1 className="text-xl font-semibold">Widget Snippet</h1>

      <Divider my={32} size="xs" className="w-full" />

      <h1 className="mb-2">
        Paste the snippet below into your index.html page, or{" "}
        <a
          href="mailto:babble@babble.fr?Subject=Need%20help%20adding%20widget"
          className="text-blue-500 cursor-pointer"
        >
          contact us
        </a>{" "}
        for help.
      </h1>
      <Code id="snippet" className="break-all" ref={ref}>
        {`<script>
        (function (w, d, s, o, f, js, fjs) {
        w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
        js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
        js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
        }(window, document, 'script', 'aw1', 'https://cdn.jsdelivr.net/gh/sisypheus/babble-widget-release@latest/widget.min.js'));
        aw1('init',
        {
        clientId: '${customization.company_id}',
        widget: {
        color: "${customization.color}",
        title: "${customization.title}",
        subtitle: "${customization.subtitle}",
        minimized: true,
        }
        }
        );
        </script>
        `}
      </Code>
      <div>
        <button
          onClick={copyToClipboard}
          className="rounded-lg text-white bg-blue-500 px-4 py-2 mt-2"
        >
          {copy ? <Check /> : "Copy to clipboard"}
        </button>
      </div>
    </div>
  );
};

export default Snippet;

import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from 'components/Sidebar'

const Layout = () => {
  return (
    <div className='flex-1 w-full h-full bg-background'>
      <Sidebar >
        <Outlet />
      </Sidebar>
    </div>
  )
}

export default Layout
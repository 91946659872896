import FullPageLoading from "components/FullPageLoading";
import { User } from "models/users";
import React, { createContext, ReactNode, useContext } from "react";
import { useQuery } from "react-query";
import { useInstance } from "service/api-client";
import {
  getTokens,
  getUser,
  login as loginUser,
  logout as logoutUser,
  register as registerUser,
} from "service/auth-client";

type AuthContext = {
  user: User | undefined;
  accessToken: string | undefined;
  refreshToken: string | undefined;
  login: (m: string, p: string) => void;
  register: (c: string, m: string, p: string, t?: string) => void;
  logout: () => void;
  refetch: () => any;
};

const AuthContext = createContext<AuthContext>({
  user: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  login: (a: string, b: string) => undefined,
  register: (a: string, b: string, c: string, d?: string) => undefined,
  logout: () => undefined,
  refetch: () => undefined,
} as AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  // TODO: find an alternative to this hack
  useInstance();
  const { data, isLoading, isError, error, refetch } = useQuery(
    "user",
    getUser
  );
  const tokens = getTokens();

  if (isLoading) return <FullPageLoading />;

  const register = (
    company: string,
    email: string,
    password: string,
    invite?: string
  ) => {
    registerUser(company, email, password, invite).then(() => {
      refetch();
    });
  };
  const login = (email: string, password: string) => {
    loginUser(email, password).then(() => {
      refetch();
    });
  };
  const logout = () => {
    logoutUser().then(() => {
      window.location.href = "/login";
    });
  };

  return (
    <AuthContext.Provider
      value={{
        user: isError ? undefined : data,
        accessToken: tokens?.accessToken,
        refreshToken: tokens?.refreshToken,
        login,
        register,
        logout,
        refetch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

import { useEmail } from "context/EmailContext";
import React, { useState } from "react";
import { apiClient } from "service/api-client";
import { Check } from "tabler-icons-react";

const Email = () => {
  const { emailVerified } = useEmail();
  const [emailSent, setEmailSent] = useState(false);

  const requestEmailVerification = async () => {
    await apiClient.post("/email-confirmation/send");
    setEmailSent(true);
  };

  return (
    <div className="flex justify-between items-center w-full">
      <div className="flex items-center justify-center space-x-4">
        {emailVerified ? (
          <button
            disabled
            className="rounded-full p-0 text-white flex items-center justify-center w-8 h-8 bg-blue-500"
          >
            <Check className="text-white" />
          </button>
        ) : (
          <button
            disabled
            className="rounded-full p-4 text-white flex items-center justify-center w-8 h-8 bg-blue-500"
          >
            1
          </button>
        )}
        {emailVerified ? (
          <p>Your account has been verified.</p>
        ) : emailSent ? (
          <p>Email sent. Please check your inbox (and spam).</p>
        ) : (
          <p>Verify your email address.</p>
        )}
      </div>
      <div>
        <button
          disabled={emailVerified ?? false}
          onClick={requestEmailVerification}
          className="py-2 px-4 bg-blue-400 rounded text-gray-50 disabled:bg-gray-50 disabled:border-blue-300 disabled:border disabled:text-blue-300 disabled:cursor-not-allowed"
        >
          {emailVerified ? "Email verified" : "Verify email"}
        </button>
      </div>
    </div>
  );
};

export default Email;

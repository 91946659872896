import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
import { setTokens } from "../service/auth-client";
import { useAuth } from "./AuthContext";
import { useAxios } from "./AxiosContext";
import { useFullUser } from "./FullUserProvider";
import { useUser } from "./UserContext";

type SocketContextType = {
  socket: Socket | null;
}

const SocketContext = createContext<SocketContextType>({} as SocketContextType);

export function useSocket(): SocketContextType {
  return useContext(SocketContext);
}

export const SocketProvider = ({ children }: { children: ReactNode }) => {
  const { fullUser } = useFullUser();
  const { token } = useUser();
  const { refreshToken, refetch } = useAuth();
  const { api } = useAxios();
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    if (!fullUser || !token)
      return;

    const socketUrl = process.env.REACT_APP_SOCKET_URL || "http://localhost:8001";

    const ws = io(socketUrl, {
      auth: {
        token: token,
        isAdmin: true,
        company_id: fullUser.company_id,
      }
    });
    setSocket(ws);
    ws.on("Unauthorized", async () => {
      console.log("Unauthorized");
      const tokens = await api?.post("/auth/refresh", {}, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        }
      });
      setTokens(tokens?.data);
      refetch();
    })

    return () => {
      socket?.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullUser, token])

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

import { Divider } from "@mantine/core";
import Email from "components/Home/Email";
import Widget from "components/Home/Widget";
import Plan from "components/Home/Plan";
import React from "react";
import Embed from "components/Home/Embed";

const Home = () => {
  return (
    <div className="flex-1 flex flex-col py-12 items-center justify-center max-w-2xl m-auto px-4 md:px-0">
      <h1 className="font-semibold text-xl self-start">Getting started</h1>

      <Divider my={32} size="xs" className="w-full" />

      <Email />

      <Divider my={32} size="xs" className="w-full" />

      <Plan />

      <Divider my={32} size="xs" className="w-full" />

      <Widget />

      <Divider my={32} size="xs" className="w-full" />

      <Embed />
    </div>
  );
};

export default Home;

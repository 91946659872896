import React from 'react';
import Current from './Current';

const Billing = () => {
  return (
    <div className='max-w-2xl py-12 m-auto md:px-0 px-4'>
      <Current />
    </div>
  )
}

export default Billing

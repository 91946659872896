import ChatBox from 'components/ChatBox'
import Layout from 'layout/Layout'
import MessageLayout from 'layout/MessageLayout'
import Billing from 'pages/Authenticated/Billing'
import All from 'pages/Authenticated/Conversations/All'
import Home from 'pages/Authenticated/Home'
import Settings from 'pages/Authenticated/Settings'
import Users from 'pages/Authenticated/Users'
import EmailVerification from 'pages/EmailVerification'
import NotFound from 'pages/Status'
import { Navigate, Route, Routes } from 'react-router-dom'
import Current from 'pages/Authenticated/Billing/Current';
import Payment from 'pages/Payment'

const Authenticated = () => {
  return (
    <Routes>
      <Route element={<Layout />} >
        <Route index element={<Navigate to={'/home'} />} />
        <Route path="/home" element={<Home />} />
        <Route path="/conversations" element={<MessageLayout />}>
          <Route path="all" element={<All />} />
          <Route path='/conversations/all/:id' element={<ChatBox />} />

          <Route path='*' element={<Navigate to={'/conversations/all'} />} />
        </Route>
        <Route path="/users" element={<Users />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/billing" element={<Billing />} >
          <Route path="/billing/current" element={<Current/>}/>
        </Route>
      </Route>
      <Route path='/login' element={<Navigate to='/' />} />
      <Route path='/register' element={<Navigate to='/' />} />
      <Route path='/confirm-email/:token' element={<EmailVerification />} />
      <Route path='/pay'  element={<Payment />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Authenticated

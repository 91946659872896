import { NotificationsProvider } from '@mantine/notifications';
import App from "components/App";
import AppProvider from 'context/AppProvider';
import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from "react-router-dom";
import { queryCache } from 'service/query-client';
import "./index.css";

render(
  <StrictMode>
    <Router>
      <QueryClientProvider client={queryCache}>
        <NotificationsProvider position='top-right'>
          <AppProvider>
            <App />
          </AppProvider>
        </NotificationsProvider>
      </QueryClientProvider>
    </Router>
  </StrictMode>,
  document.getElementById('root')
);
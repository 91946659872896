import { usePayment } from "context/PaymentContext";
import { Divider } from "@mantine/core";
import React, { useEffect, useState } from "react";
import Loading from "components/Loading";
import { Modal } from "@mantine/core";
import Plans from "../Plans";
import { useNavigate } from "react-router-dom";
import { apiClient } from "service/api-client";
import { useMediaQuery } from "@mantine/hooks";

const Current = () => {
  const { customer, subscription, subscriptions } = usePayment();
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState<string>(
    subscription?.name ?? "Free plan"
  );
  const [fullSelected, setFullSelected] = useState(subscription);
  const navigate = useNavigate();
  const smallScreen = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    setFullSelected(subscriptions?.find((s: any) => s.name === selected));
  }, [selected]);

  useEffect(() => {
    setSelected(subscription?.name ?? "Free plan");
  }, [subscription]);

  const updatePlan = async () => {
    if (subscription?.name === selected) return;
    else if (selected.toLowerCase() === "free plan") {
      await apiClient.post("/payment/update/free");
      window.location.reload();
    }
    navigate("/pay", {
      state: {
        name: selected,
        price: fullSelected.default_price.unit_amount / 100 ?? 0,
        product: fullSelected,
      },
    });
  };

  if (!customer.id) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div>
        <h1 className="text-xl font-semibold">Your subscription</h1>
      </div>
      <Divider my={32} size="xs" className="w-full" />
      <div className="border border-gray-200 rounded-md shadow-sm p-4 font-">
        {subscription?.name === "Free plan" ? (
          <p>
            You are currently on the{" "}
            <span className="font-semibold">Free plan</span>,{" "}
            <span
              className="text-blue-500 cursor-pointer"
              onClick={() => setShowModal(true)}
            >
              upgrade
            </span>{" "}
            to access all the power of Babble.
          </p>
        ) : (
          <p>
            You are currently on the{" "}
            <span className="font-semibold">{subscription?.name}</span>.
          </p>
        )}
      </div>
      <div className="mt-8 border rounded">
        <div className="p-2 bg-gray-100">
          <div className="flex justify-between items-center">
            <p>Current subscription</p>
            <p className="break-all">Price (per month)</p>
          </div>
        </div>
        <div className="flex justify-between p-2 border-y">
          <p>{subscription?.name ?? "Free plan"}</p>
          <p>
            {subscription?.plan?.amount / 100
              ? subscription?.plan?.amount / 100 + "€"
              : "Free"}
          </p>
        </div>
        <div>
          {subscription?.metadata &&
            Object.entries(subscription?.metadata).map(
              ([key, value]: any, index: number) => {
                return (
                  <div key={index} className="flex space-x-4 p-2">
                    <p>{key}</p>
                    <p>{value}</p>
                  </div>
                );
              }
            )}
        </div>
      </div>
      <div>
        <button
          onClick={() => setShowModal(true)}
          className="text-blue-500 cursor-pointer mt-4 outline-none "
        >
          Update plan
        </button>
      </div>
      <Modal
        overflow="inside"
        size={smallScreen ? "100%" : "xl"}
        centered={true}
        opened={showModal}
        onClose={() => setShowModal(false)}
      >
        <Plans
          updateAction={updatePlan}
          selected={selected}
          setSelected={setSelected}
        />
      </Modal>
    </>
  );
};

export default Current;

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQuery } from "react-query";
import { getCustomers } from "../service/customers";
import { useAuth } from "./AuthContext";

type Customer = {
  name?: string;
  createdAt: string;
  email?: string;
  id: string;
};

type CustomersContextType = {
  customers: Customer[];
};

const CustomersContext = createContext<CustomersContextType>(
  {} as CustomersContextType
);

export const useCustomers = () => useContext(CustomersContext);

export const CustomersProvider = ({ children }: { children: ReactNode }) => {
  const { data, isError, refetch } = useQuery(
    "customers",
    getCustomers
  );
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      refetch();
    }
  }, [user]);


  return (
    <CustomersContext.Provider value={{ customers: data }}>
      {children}
    </CustomersContext.Provider>
  );
};

export default CustomersProvider;

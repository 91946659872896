import React, { lazy, Suspense } from "react"
import { useUser } from "context/UserContext"
import FullPageLoading from "./FullPageLoading";
import Authenticated from "pages/Authenticated";

const App = () => {
  const { user } = useUser();
  const UnauthenticatedApp = lazy(() => import('pages/Unauthenticated'));

  return (
    <Suspense fallback={<FullPageLoading />}>
      {user ? <Authenticated /> : <UnauthenticatedApp />}
    </Suspense>
  )
}

export default App
import { Divider } from "@mantine/core";
import { usePayment } from "context/PaymentContext";
import React from "react";
import { useQueryClient } from "react-query";
import { updateCustomization } from "service/widget-customization";
import Color from "./Color/Color";

type Props = {
  prevCustomization: object;
  customization: any;
  setCustomization: any;
};

const Widget = ({
  prevCustomization,
  customization,
  setCustomization,
}: Props) => {
  const { subscription } = usePayment();
  const disabled = subscription?.name?.includes("Free") ?? false;
  const queryClient = useQueryClient();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (disabled) return;

    updateCustomization(customization);
  };

  return (
    <div className="flex-1 flex-col items-center justify-center max-w-2xl m-auto py-12">
      <h1 className="text-xl font-semibold">Widget customization</h1>

      <Divider my={32} size="xs" className="w-full" />

      <div>
        {customization && (
          <form
            className="flex flex-col space-y-6 w-full"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div>
              <label className="text-lg text-stone-800">
                Title of the widget
              </label>
              <input
                className="w-full text-stone-700 border rounded p-1"
                value={customization.title}
                onChange={(e) => {
                  if (disabled) return;
                  queryClient.setQueryData("customization", {
                    ...prevCustomization,
                    title: e.target.value,
                  });
                }}
              />
            </div>
            <div>
              <label className="text-lg text-stone-800">
                Subtitle of the widget
              </label>
              <input
                className="w-full text-stone-700 border rounded p-1"
                value={customization.subtitle}
                onChange={(e) => {
                  if (disabled) return;
                  queryClient.setQueryData("customization", {
                    ...prevCustomization,
                    subtitle: e.target.value,
                  });
                }}
              />
            </div>
            <div>
              <Color
                color={customization.color}
                setColor={setCustomization}
                disabled={disabled}
              />
            </div>

            <div>
              <button
                type="submit"
                className="bg-blue-500 py-2 px-4 rounded text-gray-50 w-auto disabled:bg-gray-400 disabled:cursor-not-allowed"
                disabled={disabled}
              >
                Update
              </button>
              {disabled && (
                <p className="text-gray-400">
                  You need to have a subscription to use this feature.
                </p>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Widget;

import {
  Alert,
  Divider,
  Input,
  InputWrapper,
  Modal,
  Table,
} from "@mantine/core";
import { useAdmins } from "context/AdminContext";
import React, { useState } from "react";
import { AlertCircle, At } from "tabler-icons-react";

const Admin = () => {
  const { admins, createAdmin } = useAdmins();
  const [error, setError] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");

  const ths = (
    <tr>
      <th>Email</th>
      <th>Role</th>
      <th>Created at</th>
    </tr>
  );

  const rows = admins?.map((admin: any) => {
    const createdAt = new Date(admin.createdAt);
    const format =
      createdAt.getFullYear() +
      "/" +
      (createdAt.getMonth() + 1) +
      "/" +
      createdAt.getDate();
    const role = admin.role_id === 1 ? "Owner" : "Admin";

    return (
      <tr key={admin.id} className="cursor-pointer">
        <td>{admin.email}</td>
        <td>{role}</td>
        <td>{format}</td>
      </tr>
    );
  });

  const createNewAdmin = async () => {
    try {
      await createAdmin(emailAddress, 0);
      setShowAddModal(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="max-w-2xl py-4 m-auto h-full">
      <h1 className="text-xl font-semibold">Admins</h1>
      <Divider my={32} size="xs" className="w-full" />
      <Table striped highlightOnHover>
        <thead>{ths}</thead>
        <tbody>{rows}</tbody>
      </Table>
      <button
        onClick={() => setShowAddModal(true)}
        className="my-4 text-blue-500"
      >
        Add an admin
      </button>
      <Alert
        hidden={error}
        icon={<AlertCircle size={16} />}
        title="Bummer!"
        color="red"
      >
        Something terrible happened! You made a mistake and there is no going
        back, your data was lost forever!
      </Alert>
      <Modal
        centered
        opened={showAddModal}
        onClose={() => setShowAddModal(false)}
        withCloseButton={false}
      >
        <div className="py-2 px-1">
          <InputWrapper
            required
            label="Email address"
            description="Please enter a correct email address"
          >
            <Input
              value={emailAddress}
              onChange={(e: any) => setEmailAddress(e.target.value)}
              icon={<At />}
              placeholder="Email address"
            />
          </InputWrapper>{" "}
          <div className="flex items-center justify-center">
            <button
              onClick={createNewAdmin}
              className="mt-6 py-2 px-4 bg-blue-500 rounded text-white"
            >
              Send
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Admin;

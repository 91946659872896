import { ChevronIcon } from "@mantine/core";
import CenterLoading from "components/CenterLoading";
import { useAxios } from "context/AxiosContext";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronLeft, ChevronRight, SignLeft } from "tabler-icons-react";

type Props = {
  conversations: any[];
  isLoading: boolean;
  collapsed: boolean;
  setCollapsed: any;
  smallScreen: boolean;
};

const ConversationsContainer = ({
  conversations,
  isLoading,
  collapsed,
  setCollapsed,
  smallScreen,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activated, setActivated] = useState("");
  const { api } = useAxios();

  useEffect(() => {
    setActivated(location.pathname.split("/").slice(-1)[0]);
  }, [location.pathname]);

  if (isLoading) {
    return (
      <div className="absolute left-0 top-0 h-screen overflow-y-auto bg-neutral-50 w-60 border-r border-r-gray-200">
        <CenterLoading />
      </div>
    );
  }

  const handleNavigate = async (to: string) => {
    const url = location.pathname.split("/").slice(0, -1).join("/") + "/" + to;
    if (url === location.pathname) return;

    const conversation = conversations.find((c) => c.customer.id === to);
    if (conversation) {
      conversation.messages.map((message: any) => {
        message.seen_by_company = true;
        return message;
      });
    }
    api?.post("/chat/conversations/company/" + to);
    navigate(url);
  };

  const displayUnread = (conversation: any) => {
    const unread = conversation.messages.reduce((acc: number, message: any) => {
      if (!message.seen_by_company) acc++;
      return acc;
    }, 0);

    return unread > 0 ? (
      <div className="bg-red-500 rounded-full p-[2px] w-6 h-6 flex items-center justify-center font-normal text-xs">
        <span className="text-white">{unread}</span>
      </div>
    ) : null;
  };

  const displayConversations = () => {
    if (conversations.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center h-full">
          <p className="text-gray-400 text-xl">No conversations yet</p>
        </div>
      );
    }

    return conversations.map((conversation, index) => {
      return (
        <div
          onClick={() => handleNavigate(conversation.customer.id)}
          key={index}
          className={`py-4 border border-l-0 border-r-0 hover:cursor-pointer flex items-center justify-between w-full border-t-0 ${
            activated === conversation.customer.id &&
            "bg-indigo-100 border-b-0 border-l-2 border-blue-500"
          }`}
        >
          <p key={conversation.customer_id} className="text-md truncate p-1">
            {conversation.customer.email
              ? conversation.customer.email
              : "Anonymous User"}
          </p>
          <div className="p-1">{displayUnread(conversation)}</div>
        </div>
      );
    });
  };

  return (
    <div className="relative">
      <div
        className={`absolute left-0 top-0 h-screen overflow-y-auto bg-neutral-50 ${
          !collapsed ? "w-60" : "w-8"
        } border-r border-r-gray-200`}
      >
        {smallScreen ? (
          collapsed ? (
            <div className="relative w-8">
              <ChevronRight
                className="absolute right-0 w-8 h-8 z-10"
                onClick={() => setCollapsed(!collapsed)}
              />
            </div>
          ) : (
            <>
              <ChevronLeft
                className="absolute right-0 w-8 h-8"
                onClick={() => setCollapsed(!collapsed)}
              />
              {displayConversations()}
            </>
          )
        ) : (
          displayConversations()
        )}
      </div>
    </div>
  );
};

export default ConversationsContainer;

import axios, { AxiosInstance } from "axios";
import { useEffect, useState } from "react";
import { getTokens, setTokens } from "./auth-client";

export const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
  },
});

export const useInstance = (): AxiosInstance => {
  const tokens = getTokens();
  const [isFirstRun, setIsFirstRun] = useState(true);

  useEffect(() => {
    const interceptor = apiClient.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !isFirstRun && tokens?.refreshToken) {
          setIsFirstRun(false);
          originalRequest._retry = true;
          const res = await apiClient.post("/auth/refresh", {}, {
            headers: {
              Authorization: `Bearer ${tokens?.refreshToken}`,
            }
          })
          setTokens(res.data);
          originalRequest.headers.Authorization = `Bearer ${res.data.accessToken}`;
          return apiClient(originalRequest);
        }
        return Promise.reject(error);
      }
    );
    return () => {
      apiClient.interceptors.response.eject(interceptor);
    }
  }, [tokens])

  apiClient.defaults.headers.common["Authorization"] = `Bearer ${tokens?.accessToken}`;
  return apiClient;
}

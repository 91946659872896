import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQuery } from "react-query";
import { getPaymentCustomer, getSubscriptions } from "service/payment";
import { useUser } from "./UserContext";

type Customer = {
  id: string;
};

type PaymentContextType = {
  subscription: any;
  customer: Customer;
  subscriptions: any;
  refetch: () => void;
};

const PaymentContext = createContext<PaymentContextType>(
  {} as PaymentContextType
);

export const usePayment = (): PaymentContextType => {
  return useContext(PaymentContext);
};

export const PaymentProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useUser();
  const { data, refetch } = useQuery("paymentCustomer", getPaymentCustomer);
  const { data: subscriptions } = useQuery(
    "paymentSubscriptions",
    getSubscriptions
  );
  const [paymentCustomer, setPaymentCustomer] = useState<Customer>(
    {} as Customer
  );
  const [subscription, setSubscription] = useState({});

  useEffect(() => {
    if (!data) return;
    setPaymentCustomer(data.customer);
    setSubscription(data.subscription);
  }, [data]);

  useEffect(() => {
    refetch();
  }, [user]);

  return (
    <PaymentContext.Provider
      value={{
        customer: paymentCustomer,
        subscription,
        subscriptions,
        refetch,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentContext;

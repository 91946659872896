import React from 'react'
import Loading from 'components/Loading'

const CenterLoading = () => {
  return (
    <div className='flex items-center justify-center h-full w-full'>
      <Loading />
    </div>
  )
}

export default CenterLoading
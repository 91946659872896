import React from 'react';
import { Card, Image, Text, Group, Badge, createStyles, Center, Button } from '@mantine/core';
import { FileX, GasStation, Gauge, ManualGearbox, Users } from 'tabler-icons-react';
import {ScrollArea} from "@mantine/core"
import {Check} from "tabler-icons-react";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    width: '230px',
  },

  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    letterSpacing: -0.25,
    textTransform: 'uppercase',
  },

  perks: {
    lineHeight: 1,
    fontWeight: 500,
    fontSize: 11,
    letterSpacing: -0.25,
    textTransform: 'uppercase',
  },

  bottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing.md,
    borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
}`,
  },

  section: {
    padding: theme.spacing.md,
    borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
}`,
  },

}));

type Perk = {
  key: string;
  value: string;
}

type Props = {
  title: string;
  perks: Perk[];
  price: number;
  selected: boolean
}

const Plan = ({title, perks, price, selected}: Props) => {
  const { classes } = useStyles();
  const features = Object.entries(perks).map(([key, value]) => (
    <div className='flex justify-between items-center' key={key}>
      <Text className={classes.perks} color="gray" size="xs">{key}</Text>
      <Text size="xs">{value}</Text>
    </div>
  ));

  const border = selected ? 'border-2 border-blue-400 ' : 'border ';

  return (
    <Card radius="md" className={border +  classes.card}>

      <Group position="apart" mb="md">
        <Text weight={500}>{title}</Text>
      </Group>

      <Card.Section className={classes.section} mt="md">
        <Text size="sm" color="dimmed" className={classes.label}>
          Features
        </Text>

        <div className='flex-col space-y-1'>
          {features}
        </div>
      </Card.Section>

      <Card.Section className={"space-y-2 " + classes.bottom}>
        <Group spacing={30}>
          <div className='flex flex-col items-center justify-center'>
            <Text size="xl" weight={700} sx={{ lineHeight: 1 }}>
              {price}€
            </Text>
            <Text size="sm" color="dimmed" weight={500} sx={{ lineHeight: 1 }} mt={3}>
              per month
            </Text>
          </div>

        </Group>
        <div className='m-auto'>
          {selected ? (
            <button className="flex items-center space-x-2 rounded-lg border-2 border-blue-500 px-4 py-1 text-gray-50">
              <Check className='text-blue-500'/>
              <p className='text-blue-500'>Selected</p>
            </button>
          ) : (
              <button className="rounded-lg bg-blue-500 px-4 py-1 text-gray-50">
                Choose plan
              </button>
            )}
        </div>
      </Card.Section>
    </Card>
  );
}

export default Plan;

import React, { ReactNode } from "react";
import { AuthProvider } from "./AuthContext";
import { SocketProvider } from "./SocketContext";
import { UserProvider } from "./UserContext";
import { AxiosProvider } from "./AxiosContext";
import { ChatProvider } from "./ChatContext";
import { EmailProvider } from "./EmailContext";
import { FullUserProvider } from "./FullUserProvider";
import { CustomizationProvider } from "./CustomizationContext";
import { PaymentProvider } from "./PaymentContext";
import CustomersProvider from "./CustomersContext";
import AdminsProvider from "./AdminContext";

type Props = {
  children: ReactNode;
};

const AppProvider = ({ children }: Props) => {
  return (
    <AxiosProvider>
      <AuthProvider>
        <EmailProvider>
          <UserProvider>
            <FullUserProvider>
              <PaymentProvider>
                <SocketProvider>
                  <ChatProvider>
                    <CustomizationProvider>
                      <CustomersProvider>
                        <AdminsProvider>
                          {children}
                        </AdminsProvider>
                      </CustomersProvider>
                    </CustomizationProvider>
                  </ChatProvider>
                </SocketProvider>
              </PaymentProvider>
            </FullUserProvider>
          </UserProvider>
        </EmailProvider>
      </AuthProvider>
    </AxiosProvider>
  );
};

export default AppProvider;

import { apiClient } from "./api-client";

export const getPlan = async () => {
  const { data } = await apiClient.get("/plan");
  return data;
}

export const getPaymentCustomer = async () => {
  try {
    const { data } = await apiClient.get("/payment/customer");
    return { customer: data.customer, subscription: data.subscription};
  } catch (err) {
    return {};
  }
}

const plansOrder = ["Free plan", "Basic plan", "Premium plan", "Enterprise plan"];

export const getSubscriptions = async () => {
  try {
    const { data } = await apiClient.get("/payment/subscriptions");
    return data?.sort((a: any, b: any) => {
      const aIndex = plansOrder.indexOf(a.name);
      const bIndex = plansOrder.indexOf(b.name);
      return aIndex - bIndex;
    });
  } catch (err) {
    console.log(err);
    return [];
  }
}

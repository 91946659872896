import React from 'react'

type Props = {
  message: {
    id: number,
    customer_id: string,
    company_id: string,
    admin_id: number,
    content: string,
    sender: string,
    createdAt: string,
  }
  messages: any[],
  index: number
}

const Message = ({ message, messages, index }: Props) => {
  const nextMessage = index == 0 ? undefined : messages.at(index - 1);

  const formatDate = (date: string) => {
    const formatted = new Date(date);
    const diff = Math.abs(Date.now() - formatted.getTime());
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff / 1000) / 60);
    if (days > 0) {
      return `${days > 1 ? days + " days" : days + " day"} ago`;
    } else if (hours > 0) {
      return `${days > 1 ? hours + " hours" : hours + " hour"} ago`;
    } else if (minutes > 0) {
      return `${minutes > 1 ? minutes + " minutes" : minutes + " minute"} ago`;
    } else {
      return "Seconds ago";
    }
  }

  const displayDate = (sender: string): boolean => {
    if (!nextMessage)
      return true;
    if (nextMessage.sender === sender)
      return false;
    return true;
  }

  return (
    <>
      {message.sender === 'CUSTOMER' ? (
        <div className='w-full px-4 flex flex-col justify-start items-start'>
          <div className='px-4 py-2 bg-gray-200 rounded-md whitespace-pre-line'>
            <p>{message.content}</p>
          </div>
          <div>
            <p>{displayDate(message.sender) && formatDate(message.createdAt)}</p>
          </div>
        </div>
      ) : (
        <div className='w-full px-4 flex flex-col justify-end items-end'>
          <div className='px-4 py-2 bg-blue-500 rounded-md'>
            <p className='text-white whitespace-pre-line'>{message.content}</p>
          </div>
          <div>
            <p>{displayDate(message.sender) && formatDate(message.createdAt)}</p>
          </div>
        </div>
      )}
    </>
  )
}

export default Message

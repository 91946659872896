import { User } from 'models/users';
import { apiClient } from './api-client';

type Tokens = {
  accessToken: string,
  refreshToken: string,
}

const handleUser = (user: User, tokens?: { accessToken: string, refreshToken: string }): User => {
  if (tokens) {
    localStorage.setItem('accessToken', tokens.accessToken);
    localStorage.setItem('refreshToken', tokens.refreshToken);
  }
  return user;
}

export const getEmailVerified = async (): Promise<boolean> => {
  const { data } = await apiClient.get('/auth/isemailconfirmed');
  return data;
}

export const getToken = (): string | undefined => {
  return localStorage.getItem('accessToken') || undefined;
}

export const getTokens = (): Tokens | undefined => {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  return accessToken && refreshToken ? { accessToken, refreshToken } : undefined;
}

export const setTokens = (tokens: Tokens) => {
  console.log('set tokens');
  localStorage.setItem('accessToken', tokens.accessToken);
  localStorage.setItem('refreshToken', tokens.refreshToken);
}

export const getUser = async (): Promise<User> => {
  const token = localStorage?.getItem('accessToken');

  if (!token)
    throw new Error('No token found');

  const response = await apiClient.get('/auth/me', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  if (response.status >= 400)
    throw new Error(response.data.message || 'An error occured');
  return handleUser(response.data);
}

export const register = async (company: string, email: string, password: string, invite?: string): Promise<User> => {
  const response = await apiClient.post('/auth/signup', {
    company,
    email,
    password,
    invite
  });
  return handleUser(response.data.user, response.data.tokens);
}

export const refresh = async (): Promise<User> => {
  const token = localStorage?.getItem('refreshToken');

  if (!token)
    throw new Error('No token found');

  const response = await apiClient.post('/auth/refresh', {}, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return handleUser(response.data.user, response.data.tokens);
}

export const login = async (email: string, password: string): Promise<User> => {
  const response = await apiClient.post('/auth/signin', {
    email,
    password
  })
  return handleUser(response.data.user, response.data.tokens);
}

export const logout = async (): Promise<void> => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  return Promise.resolve();
}

import CenterLoading from 'components/CenterLoading';
import Message from 'components/Message';
import { useAxios } from 'context/AxiosContext';
import { useChat } from 'context/ChatContext';
import { useSocket } from 'context/SocketContext';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Send } from 'tabler-icons-react';

type Props = {
  customer_id?: string
}

const ChatBox = ({ customer_id }: Props) => {
  const location = useLocation();
  const { pathname } = location;
  const id = pathname.split('/').slice(-1)[0] || customer_id;
  const { socket } = useSocket();
  const { api } = useAxios();
  const [message, setMessage] = useState('');
  const { conversations, isLoading, setConversations } = useChat();
  const [conversation, setConversation] = useState<any>(undefined);

  useEffect(() => {
    if (conversations.length !== 0 && id)
      setConversation(conversations.find((conversation: any) => conversation.customer.id === id));
  }, [conversations, id]);

  const handleMessageSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (message.length === 0) 
      return;
    
    handleSendMessage();
    setMessage('');
  }

  const handleSendMessage = (): void => {
    socket?.emit('admin-message', {
      content: message,
      to: {
        id,
      }
    });
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  }

  const setMessagesAsSeen = () => {
    setConversations((prev: any) => {
      const newConversations = prev.map((conversation: any) => {
        if (conversation.customer.id === id) {
          let changed = false;
          conversation.messages.map((message: any) => {
            if (!message.seen_by_company) {
              message.seen_by_company = true;
              changed = true;
            }
            return message;
          })
          if (changed)
            api?.post("/chat/conversations/company/" + id);
        }
        return conversation;
      })
      return newConversations;
    })
  }

  return (
    <>
      <div className='h-full w-full flex flex-col flex-1'>
        <div className='p-4'>
          <p className='text-lg'>
            {conversation?.customer?.email || "Anonymous User"}
          </p>
        </div>
        <div className='flex basis-0 flex-grow flex-1 flex-shrink flex-col h-full w-full overscroll-contain'>
          <div className='w-full flex-grow flex-shrink basis-0 flex flex-col-reverse relative overflow-y-auto scroll-smooth'>
            {isLoading ? (
              <CenterLoading />
            ) : (
              <div className='overflow-y-scroll scroll-smooth flex flex-col-reverse pr-2'>
                {
                  conversation && conversation.messages.map((message: any, index: number) => {
                    return (
                      <div key={message.id} className='flex py-[2px]'>
                        <Message messages={conversation.messages} message={message} index={index} key={message.id} />
                      </div>
                    )
                  })
                }
              </div>
            )}
          </div>
        </div>
        {/* TODO: extract this in a component */}
        <div className='w-full block'>
          <form className='flex items-center p-1 border border-l-0' onSubmit={(e) => handleMessageSubmit(e)}>
            <input onChange={handleChange} onClick={setMessagesAsSeen} value={message} className='p-2 w-full outline-none' />
            <Send onClick={handleSendMessage} className='hover:text-blue-500 cursor-pointer' />
          </form>
        </div>
      </div>
    </>
  )
}

export default ChatBox

import { apiClient } from "./api-client";

export const getAdmins = async () => {
  try {
    const response = await apiClient.get("/admin/all");
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createAdmin = async (email: string, role: number) => {
  try {
    const response = await apiClient.post("/admin/create", { email, role });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

import CenterLoading from 'components/CenterLoading';
import { useChat } from 'context/ChatContext';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const All = () => {
  const { conversations, isLoading } = useChat();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (conversations.length) {
      location.pathname = `/conversations/all/${conversations[0].customer.id}`;
      navigate(location.pathname);
    }
  }, [conversations]);

  return (
    <>
      {isLoading ? (
        <CenterLoading />
      ) : (
        null
      )}
    </>
  )
}

export default All
import { usePayment } from 'context/PaymentContext';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Check } from 'tabler-icons-react';

const Plan = () => {
  const navigate = useNavigate();
  const { subscription } = usePayment();
  let name = subscription?.name ?? "Free plan";
  const hasSubscription = name.includes("Free") ? false : true;

  return (
    <div className='flex justify-between items-center w-full'>
      <div className='flex items-center justify-center space-x-4'>
        {hasSubscription ? (
          <button
            disabled
            className="rounded-full p-0 text-white flex items-center justify-center w-8 h-8 bg-blue-500"
          >
            <Check className="text-white" />
          </button>
        ) : (
          <button
            disabled
            className="rounded-full p-4 text-white flex items-center justify-center w-8 h-8 bg-blue-500"
          >
            2
          </button>
        )}
        {hasSubscription ? (
          <p>
            You are ready to engage with your customers.
          </p>
        ) : (
          <p>
            Update your plan to engage with customers.
          </p>
        )}
      </div>
      <div>
        <button disabled={hasSubscription ?? false} onClick={() => navigate('/billing')} className='py-2 px-4 bg-blue-400 rounded text-gray-50 disabled:bg-gray-50 disabled:border-blue-300 disabled:border disabled:text-blue-300 disabled:cursor-not-allowed'>
          {hasSubscription ? 'Subscribed' : 'Subscribe'}
        </button>
      </div>
    </div>
  )
}

export default Plan

import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { QueryKey, SetDataOptions, useQuery, useQueryClient } from 'react-query';
import { Updater } from 'react-query/types/core/utils';
import { getCustomization } from 'service/widget-customization';

type Customization = {
  title: string;
  subtitle: string;
  color: string;
  customized: boolean;
}

let defaultCustomization = {
  title: '',
  subtitle: '',
  color: '',
  customized: false
}

type CustomizationContextType = {
  customization: Customization;
  setCustomization: <TData>(queryKey: QueryKey, updater: Updater<TData | undefined, TData>, options?: SetDataOptions | undefined) => TData;
}

const CustomizationContext = createContext<CustomizationContextType>({} as CustomizationContextType);

export const useCustomization = (): CustomizationContextType => {
  return useContext(CustomizationContext);
}

export const CustomizationProvider = ({ children }: { children: ReactNode }) => {
  const { data, isError, isLoading } = useQuery('customization', getCustomization);
  const queryClient = useQueryClient();

  return (
    <CustomizationContext.Provider value={{ customization: isLoading || isError ? defaultCustomization : data, setCustomization: queryClient.setQueryData }} >
      {children}
    </CustomizationContext.Provider>
  )
}

export default CustomizationContext
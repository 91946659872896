import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useAxios } from './AxiosContext';
import { useUser } from './UserContext';

type FullUserContextType = {
  fullUser: any | undefined,
};

const FullUserContext = createContext<FullUserContextType>({ fullUser: undefined });

const useFullUser = () => {
  return useContext(FullUserContext);
}

const FullUserProvider = ({ children }: { children: ReactNode }) => {
  const { api } = useAxios();
  const { user } = useUser();

  const getFullUserInfo = async () => {
    const response = await api?.get('/auth/info');
    return response?.data;
  }

  useEffect(() => {
    if (!user)
      return;

    refetch();
  }, [user])

  const { data, refetch } = useQuery('fullUserInfo', getFullUserInfo);

  return (
    <FullUserContext.Provider value={{ fullUser: data ?? undefined }}>
      {children}
    </FullUserContext.Provider>
  )
}

export { FullUserProvider, useFullUser };

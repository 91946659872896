import { AxiosInstance } from "axios";
import React, { createContext, ReactNode, useContext } from "react";
import { useInstance } from "service/api-client";

type AxiosContext = {
  api: AxiosInstance | undefined;
}

const AxiosContext = createContext<AxiosContext>({} as AxiosContext);

export function useAxios(): AxiosContext {
  return useContext(AxiosContext);
}

export const AxiosProvider = ({ children }: { children: ReactNode }) => {
  const api = useInstance();

  return (
    <AxiosContext.Provider value={{ api }}>
      {children}
    </AxiosContext.Provider>
  )
}
import {
  createStyles,
  Group,
  Navbar,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import { useAuth } from "context/AuthContext";
import React, { ReactNode } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Home2,
  Icon as TablerIcon,
  Logout,
  Message,
  Users,
  Settings,
  Coin,
} from "tabler-icons-react";

const useStyles = createStyles((theme) => ({
  link: {
    borderRadius: theme.radius.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.white,
    opacity: 0.85,

    "&:hover": {
      opacity: 1,
      backgroundColor: "#20315c",
    },
  },

  active: {
    opacity: 1,
    "&, &:hover": {
      backgroundColor: "#20315c",
    },
  },
}));

interface NavbarLinkProps {
  icon: TablerIcon;
  label: string;
  active?: boolean;
  onClick?(): void;
}

function NavbarLink({ icon: Icon, label, active, onClick }: NavbarLinkProps) {
  const { classes, cx } = useStyles();
  return (
    <Tooltip label={label} position="right" withArrow transitionDuration={0}>
      <UnstyledButton
        onClick={onClick}
        className={cx(
          classes.link,
          {
            [classes.active + " bg-[#193375]"]: active,
          },
          " md:p-4 p-1"
        )}
      >
        <Icon size={28} />
      </UnstyledButton>
    </Tooltip>
  );
}

const useNavbarStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: "#1a284a",
    width: "5rem",
    border: "none",
  },
}));

const Sidebar = ({ children }: { children: ReactNode }) => {
  const { classes } = useNavbarStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const { logout } = useAuth();

  return (
    <>
      <div className="fixed h-screen top-0 left-0">
        <Navbar className={classes.navbar + " md:w-20 w-12"}>
          <Navbar.Section grow className="mt-8">
            <Group direction="column" align="center" spacing={24}>
              <NavbarLink
                icon={Home2}
                onClick={() => navigate("home")}
                active={pathname.startsWith("/home")}
                label="Home"
              />
              <NavbarLink
                icon={Message}
                onClick={() => navigate("conversations/all")}
                active={pathname.startsWith("/conversations")}
                label="Conversations"
              />
              <NavbarLink
                icon={Users}
                onClick={() => navigate("users")}
                active={pathname.startsWith("/users")}
                label="Users"
              />
              <NavbarLink
                icon={Settings}
                onClick={() => navigate("settings")}
                active={pathname.startsWith("/settings")}
                label="Settings"
              />
              <NavbarLink
                icon={Coin}
                onClick={() => navigate("billing")}
                active={pathname.startsWith("/billing")}
                label="Billing"
              />
            </Group>
          </Navbar.Section>
          <Navbar.Section>
            <Group direction="column" align="center" spacing={16}>
              <div className="mb-8">
                <NavbarLink icon={Logout} label="Logout" onClick={logout} />
              </div>
            </Group>
          </Navbar.Section>
        </Navbar>
      </div>
      <div className="md:ml-20 ml-12 relative h-full">
        <Outlet />
      </div>
    </>
  );
};

export default Sidebar;

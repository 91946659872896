import React from "react";
import { useNavigate } from "react-router-dom";

const Embed = () => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between items-center w-full">
      <div className="flex items-center justify-center space-x-4">
        <button
          disabled
          className="rounded-full p-4 text-white flex items-center justify-center w-8 h-8 bg-blue-500"
        >
          4
        </button>
        <p>Add the widget to your website.</p>
      </div>
      <div>
        <button
          onClick={() => navigate("/settings")}
          className="py-2 px-4 bg-blue-400 rounded text-gray-50 disabled:bg-gray-50 disabled:border-blue-300 disabled:border disabled:text-blue-300 disabled:cursor-not-allowed"
        >
          Add the widget
        </button>
      </div>
    </div>
  );
};

export default Embed;
